import React from 'react';
import { Helmet } from 'react-helmet';

export const PageTitle = ({ children }) => (
    <Helmet>
        <title>{children} | Stocompass</title>
    </Helmet>
);


