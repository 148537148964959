
import React, { useState, useEffect } from 'react';
import { useAppSelector, useAppDispatch } from '../../../../../hooks/reduxHooks';
import * as S from '../../../selects/BaseSelect/BaseSelect.styles'
import { ColorRing } from 'react-loader-spinner';
import { fetchSymbols, fetchOptionBySymbol, fetchOptionSummaryBySymbol, fetchOIChanges } from '../../../../../store/slices/optionSlice';
import { SymbolItem } from './SymbolItem';
import { CategoryItem } from './CategoryItem';
import styled from 'styled-components';

const { Option } = S.Select; 

export const SearchOption = () => {
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const data = useAppSelector((state) => state.option.symbols.list);
    const currentSymbol = useAppSelector((state) => state.option.symbol);
    const isLoading = useAppSelector((state) => state.option.symbols.isLoading);

    const dispatch = useAppDispatch();

    useEffect(() => {

        dispatch(fetchSymbols());
        dispatch(fetchOIChanges());
        const optionGroupParam = {
            symbol: currentSymbol,
            prevDate: 'default',
            currDate: 'default',
        }
        const optionSummaryParam = {
            symbol: currentSymbol,
            dataDate: 'default',
        }
        dispatch(fetchOptionBySymbol(optionGroupParam));
        dispatch(fetchOptionSummaryBySymbol(optionSummaryParam));

    }, []);

    const handleChange = (symbol) => {
        const optionGroupParam = {
            symbol: symbol,
            prevDate: 'default',
            currDate: 'default',
        }
        const optionSummaryParam = {
            symbol: symbol,
            dataDate: 'default',
        }
        dispatch(fetchOptionBySymbol(optionGroupParam));
        dispatch(fetchOptionSummaryBySymbol(optionSummaryParam));

    }
    return (
        <S.Select
            showSearch
            style={{
                width: '100%',
                fontSize: '5rem'
            }}
            //value={currentSymbol}
            placeholder='Search'
            onChange={(symbol) => handleChange(symbol)}
            //onSelect={() => setIsDropdownVisible(true)}
            onSelect={() => setIsDropdownVisible(false)}
            optionLabelProp="label"
            maxTagCount='responsive'
            //defaultValue='Search Symbol'
            //onDropdownVisibleChange={(visible) => setIsDropdownVisible(true)}
            onDropdownVisibleChange={(visible) => setIsDropdownVisible(visible)}
            popupMatchSelectWidth={false}
            open={isDropdownVisible}
        //loading={isLoading} 

        >
            {isLoading ? (
                <Option key="loading" value="loading" label="loading">
                    <LoaderDiv>
                        <ColorRing
                            visible={true}
                            height="80"
                            width="80"
                            ariaLabel="blocks-loading"
                            wrapperStyle={{}}
                            wrapperClass="blocks-wrapper"
                            colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                        />
                    </LoaderDiv>
                </Option>
            ) : (
                data.map((item, index) => (
                    <Option key={index} value={item.symbol} label={item.symbol}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <SymbolItem {...item} />
                            <CategoryItem {...item} />
                        </div>
                    </Option>
                )))}

        </S.Select>
    )
}


const LoaderDiv = styled.div`
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  /* background-color: rgb(37,40,75); */
`;