import React from 'react';
import { NewPasswordForm } from './NewPasswordForm/NewPasswordForm';
import { data as t } from '../../locale/translation'
import { PageTitle } from '../../components/common/PageTitle';

const NewPasswordPage = () => {
  return (
    <>
      <PageTitle>{t.common.newPassword}</PageTitle>
      <NewPasswordForm />
    </>
  );
};

export default NewPasswordPage;
