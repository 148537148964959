import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { SearchOption } from '../SearchOption/SearchOption'
import { categoriesList } from '../../../../../constants/categoriesList';
import { useResponsive } from '../../../../../hooks/useResponsive';
import * as S from './HeaderSearch.styles';


export const HeaderSearch = () => {
  const { mobileOnly, isTablet } = useResponsive();

  const { pathname } = useLocation();

  const [query, setQuery] = useState('');
  //const [components] = useState(configComponents);

  const [isModalOpen, setModalOpen] = useState(false);
  const [isOverlayOpen, setOverlayOpen] = useState(false);

  // const sortedResults = query
  //   ? categoriesList.reduce((acc, current) => {
  //     const searchResults = components.filter(
  //       (component) =>
  //         component.categories.includes(current.name) &&
  //         component.keywords.some((keyword) => keyword.includes(query)),
  //     );

  //     return searchResults.length > 0 ? acc.concat({ category: current.name, components: searchResults }) : acc;
  //   }, [])
  //   : null;

  useEffect(() => {
    setModalOpen(false);
    setOverlayOpen(false);
  }, [pathname]);

  return (
    <>
      {mobileOnly && (
        <>
          <SearchOption />
        </>
      )}

      {isTablet && (
        <SearchOption />
      )}
    </>
  );
};
