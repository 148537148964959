import React, { useEffect } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { BaseForm } from "../../../components/common/form/BaseForm/BaseForm";
import { VerificationCodeInput } from "../../../components/common/inputs/VerificationCodeInput/VerificationCodeInput";
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import { notificationController } from "../../../controllers/notificationController";
import { activate } from "../../../store/slices/userSlice";
import VerifyEmailImage from "../../../assets/images/verify-email.webp";
import * as Auth from "../../../layouts/AuthLayout/AuthLayout.styles";
import * as S from "./SecurityCodeForm.styles";
import { BaseImage } from "../.././../components/common/base/BaseImage/BaseImage";
import { BaseSpin } from "../.././../components/common/base/BaseSpin/BaseSpin";
import { data as t } from "../../../locale/translation";

export const SecurityCodeForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const fromPath = location.state?.from || 'auth/login'; 

  const s1 = useAppSelector((state) => state.user.signIn);
  const s2 = useAppSelector((state) => state.user.signUp);

  const s = fromPath === "/auth/login" ? s1 : s2;

  //const navigateBack = useCallback(() => navigate(-1), [navigate]);
  // const navigateForward = useCallback(() => navigate('/auth/new-password'), [navigate]);
  const dispatch = useAppDispatch();

  const handleBack = () => {
    window.location.href = fromPath;
  };


  const handleInput = (inputValue) => {
    //当验证码长度达到6位时，自动提交数字
    if (inputValue.length === 6) {
      const verificationInput = {
        email: s.user.email,
        code: inputValue,
      };

      dispatch(activate(verificationInput))
        .then(() => {
          if (s.user.isActivate) {
            notificationController.success({
              message: s.message,
            });
            // console.log(s1.token);
            // sessionStorage.setItem('accessToken', s.token);
            // sessionStorage.setItem('firstName', s.user.firstName);
            // sessionStorage.setItem('lastName', s.user.lastName);
            navigate("/auth/login");
            //sessionStorage.setItem("accessToken", s1.token);
            //navigate("/open-interest");
          } else {
            notificationController.error({
              message: s.message,
            });
            navigate("/auth/login");
          }
        })
        .catch((err) => {});
    }
  };

  useEffect(() => {
    //console.log(s1.token);
    sessionStorage.setItem("accessToken", s1.token);
  }, [s1.count]);

  return (
    <Auth.FormWrapper>
      <BaseForm layout="vertical" requiredMark="optional">
        {/* <Auth.BackWrapper onClick={navigate(previousPath)}> */}
        <Auth.BackWrapper onClick={handleBack}>
          <Auth.BackIcon />
          {t.common.back}
        </Auth.BackWrapper>
        <S.ContentWrapper>
          <S.ImageWrapper>
            <BaseImage src={VerifyEmailImage} alt="Not found" preview={false} />
          </S.ImageWrapper>
          <Auth.FormTitle>{t.securityCodeForm.title}</Auth.FormTitle>
          <S.VerifyEmailDescription>
            {t.common.verifCodeSent}
          </S.VerifyEmailDescription>
          {s.isLoading ? (
            <BaseSpin />
          ) : (
            <VerificationCodeInput autoFocus onChange={handleInput} />
            //<VerificationCodeInput autoFocus />
          )}
          <Link to="/" target="_blank">
            <S.NoCodeText>{t.securityCodeForm.noCode}</S.NoCodeText>
          </Link>
        </S.ContentWrapper>
      </BaseForm>
    </Auth.FormWrapper>
  );
};
