import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { withLoading } from '../hocs/withLoading.hoc';
import Login from '../pages/LoginPage/LoginPage'
import ForgotPasswordPage from '../pages/ForgotPasswordPage/ForgotPasswordPage';
import Error404Page from '../pages/Error404Page/Error404Page';
import NewPasswordPage from '../pages/NewPasswordPage/NewPasswordPage';
import SecurityCodePage from '../pages/SecurityCodePage/SecurityCodePage';
import SignUpPage from '../pages/SignUpPage/SignUpPage';
import MainLayout from '../layouts/main/MainLayout/MainLayout';
import RequireAuth from './RequireAuth';


import OpenInterestPage from '../pages/OpenInterestPage/OpenInterestPage';
import DexPage from '../pages/DexPage/DexPage';
import GexPage from '../pages/GexPage/GexPage';

const OpenInterestDashboard = withLoading(OpenInterestPage);
const GexDashboard = withLoading(GexPage);
const DexDashboard = withLoading(DexPage);
const Error404 = withLoading(Error404Page);

const AuthLayout = React.lazy(() => import('../layouts/AuthLayout/AuthLayout'));
const AuthLayoutFallback = withLoading(AuthLayout);

export const BASE_PATH = '/';

export const AppRouter = () => {
    // 使用自定义 Hook
   
const protectedLayout = (
    <RequireAuth>
        <MainLayout />
    </RequireAuth>
);
return (
    <BrowserRouter>
        <Routes>
            <Route path={BASE_PATH} element={protectedLayout}>
                <Route path="open-interest" element={<OpenInterestDashboard />} />
                <Route path="gex" element={<GexDashboard />} />
                <Route path="dex" element={<DexDashboard />} />

            </Route>
            <Route path="/auth" element={<AuthLayoutFallback />}>
                <Route path="login" element={<Login />} />
                <Route path="forgot-password" element={<ForgotPasswordPage />} />
                <Route path="security-code" element={<SecurityCodePage />} />
                <Route path="sign-up" element={<SignUpPage />} />
                <Route path="new-password" element={<NewPasswordPage />} />
            </Route>


        </Routes>
    </BrowserRouter>
)
}

