import React from 'react';
import { Col, Row } from 'antd';
import { PageTitle } from '../../components/common/PageTitle';
import { withLoading } from '../../hocs/withLoading.hoc';
// import TradingMiniChart from '../../components/charts/TradingMiniChart';
//import TradingStatisticsCards from '../../components/cards/TradingStatisticsCards';
import DivergingStackedBarChart from '../../components/charts/DivergingStackedBarChart';
import { OptionSummaryCard } from '../../components/cards/optionSummaryCard/OptionSummaryCard/OptionSummaryCard';
import { useAppSelector } from '../../hooks/reduxHooks';
import { useResponsive } from '../../hooks/useResponsive';
import { OIChangeTabs } from '../../components/cards/oiChangeTabs/OIChangeTabs';

import * as S from '../OpenInterestPage/OpenInterestPage.styles';

const GexPage = () => {
  const { isTablet, isDesktop } = useResponsive();

  let expiryDateList = useAppSelector((state) => state.option.options.dates);
  const OptionSummaryCardWithLoading = withLoading(OptionSummaryCard);
  const DivergingStackedBarChartWithLoading = withLoading(DivergingStackedBarChart);
// const TradingMiniChartWithLoading = withLoading(TradingMiniChart);
// const TradingStatisticsCardsWithLoading = withLoading(TradingStatisticsCards);

  const desktopLayout = (
    <Row>
      <S.LeftSideCol xl={12} xxl={15}>
        <Row gutter={[30, 30]}>
          <Col span={24}>
            {/* <TradingStatisticsCardsWithLoading /> */}
          </Col>
          {expiryDateList.map((item, index) => {
            const [expiryDate, periodType] = item.split('_');
            return (
              <Col span={24} key={index}>
                <DivergingStackedBarChartWithLoading expiryDate={expiryDate} periodType={periodType} category='Gex' />
              </Col>
            );
          })}
        </Row>
      </S.LeftSideCol>

      <S.RightSideCol xl={12} xxl={9}>
        <OIChangeTabs />
        {/* <div id="blood-screening"> */}
        {/* <TradingMiniChart /> */}

        {/* </div> */}
        {/* <S.Space /> */}
        {/* <S.ScrollWrapper id="patient-timeline"> */}
        {/* <OptionSummaryCardWithLoading /> */}
        {/* <OIChangeTabs/> */}
        {/* </S.ScrollWrapper> */}
      </S.RightSideCol>
    </Row>
  );

  return (
    <>
      <PageTitle>Open Interest</PageTitle>
      {desktopLayout}
    </>
  );
};

export default GexPage;




