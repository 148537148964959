import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { BaseForm } from '../../../components/common/form/BaseForm/BaseForm';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { signIn } from '../../../store/slices/userSlice';
import { notificationController } from '../../../controllers/notificationController';
import { ReactComponent as FacebookIcon } from '../../../assets/icons/facebook.svg';
import { ReactComponent as GoogleIcon } from '../../../assets/icons/google.svg';
import { data as t } from '../../../locale/translation'
import * as S from './LoginForm.styles';
import * as Auth from '../../../layouts/AuthLayout/AuthLayout.styles';


// export const initValues = {
//   email: 'hello@stocompass.com',
//   password: '123456',
// };

export const initValues = {
  email: '',
  password: '',
};

export const LoginForm = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const s = useAppSelector((state) => state.user.signIn)

  useEffect(() => {
    if (s.status !== undefined) {
      if (s.status) {
        if (s.user.isActivate === '0') {
          notificationController.warning({
            message: s.message,
          });
          navigate('/auth/security-code', { state: { from: window.location.pathname } })
        } else {
          notificationController.success({
            message: s.message,
          });
          sessionStorage.setItem('accessToken', s.token);
          sessionStorage.setItem('firstName', s.user.firstName);
          sessionStorage.setItem('lastName', s.user.lastName);
          navigate("/open-interest");
        }

      } else if (s.message !== '') {
        notificationController.error({
          message: s.message,
        });
      }
    }
  }, [s.count]);

  const handleSubmit = (values) => {
    const loginInput = {}
    loginInput.email = values.email
    loginInput.password = values.password
    dispatch(signIn(loginInput))
      .unwrap()
      .catch((err) => {
        // notificationController.error({ message: err.message });
        //setLoading(false);
      });
  };

  const handleForgotPasswordClick = () => {
    // 使用 navigate 进行导航，并传递 state
    navigate('/auth/forgot-password', { state: { from: '/auth/login' } });
  };

  return (
    <Auth.FormWrapper>
      <BaseForm layout="vertical" onFinish={handleSubmit} requiredMark="optional" initialValues={initValues}>
        <Auth.FormTitle>{t.common.login}</Auth.FormTitle>
        <S.LoginDescription>{t.login.loginInfo}</S.LoginDescription>
        <Auth.FormItem
          name="email"
          label={t.common.email}
          rules={[
            { required: true, message: t.common.requiredField },
            {
              type: 'email',
              message: t.common.notValidEmail,
            },
          ]}
        >
          <Auth.FormInput placeholder={t.common.email} />
        </Auth.FormItem>
        <Auth.FormItem
          label={t.common.password}
          name="password"
          rules={[{ required: true, message: t.common.requiredField }]}
        >
          <Auth.FormInputPassword placeholder={t.common.password} />
        </Auth.FormItem>
        <Auth.ActionsWrapper>
          <BaseForm.Item name="rememberMe" valuePropName="checked" noStyle>
            <Auth.FormCheckbox>
              <S.RememberMeText>{t.login.rememberMe}</S.RememberMeText>
            </Auth.FormCheckbox>
          </BaseForm.Item>
          {/* <Link to="/auth/forgot-password"> */}
          <S.ForgotPasswordText onClick={handleForgotPasswordClick}>
            {t.common.forgotPass}
          </S.ForgotPasswordText>
          {/* </Link> */}
        </Auth.ActionsWrapper>
        <BaseForm.Item noStyle>
          <Auth.SubmitButton type="primary" htmlType="submit" loading={s.isLoading}>
            {t.common.login}
          </Auth.SubmitButton>
        </BaseForm.Item>
       
        {/* <BaseForm.Item noStyle>
          <Auth.SocialButton type="default" htmlType="submit">
            <Auth.SocialIconWrapper>
              <GoogleIcon />
            </Auth.SocialIconWrapper>
            {t.login.googleLink}
          </Auth.SocialButton>
        </BaseForm.Item> */}
        {/* <BaseForm.Item noStyle>
          <Auth.SocialButton type="default" htmlType="submit">
            <Auth.SocialIconWrapper>
              <FacebookIcon />
            </Auth.SocialIconWrapper>
            {t.login.facebookLink}
          </Auth.SocialButton>
        </BaseForm.Item> */}
        <Auth.FooterWrapper>
          <Auth.Text>
            {t.login.noAccount}{' '}
            <Link to="/auth/sign-up">
              <Auth.LinkText>{t.common.here}</Auth.LinkText>
            </Link>
          </Auth.Text>
        </Auth.FooterWrapper>
      </BaseForm>
    </Auth.FormWrapper>
  );
};
