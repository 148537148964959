import React from 'react';
import { DashboardOutlined, HomeOutlined } from '@ant-design/icons';

export const sidebarNavigation = [
  {
    title: 'Overview',
    key: 'apps',
    icon: <HomeOutlined />,
    children: [
      {
        title: 'Open Interest',
        key: 'overview-dashboard',
        url: '/open-interest',
        icon: <DashboardOutlined />,
      },
      {
        title: 'Gex',
        key: 'gex-dashboard',
        url: '/gex',
        icon: <DashboardOutlined />,
      },
      // {
      //   title: 'Dex',
      //   key: 'dex-dashboard',
      //   url: '/dex',
      //   icon: <DashboardOutlined />,
      // },
    ],
  },
];

