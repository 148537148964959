import React from 'react';
//import { NotificationsDropdown } from '../components/notificationsDropdown/NotificationsDropdown';
// import { ProfileDropdown } from '../components/profileDropdown/ProfileDropdown/ProfileDropdown';
import * as S from '../Header.styles';
import { BaseRow } from '../../../common/base/BaseRow/BaseRow';
import { BaseCol } from '../../../common/base/BaseCol/BaseCol';

export const MobileHeader = ({ toggleSider, isSiderOpened }) => {
  return (
    <BaseRow justify="space-between" align="middle">
      <BaseCol>
        {/* <ProfileDropdown /> */}
      </BaseCol>

      <S.BurgerCol>
        {/* <S.MobileBurger onClick={toggleSider} isCross={isSiderOpened} /> */}
      </S.BurgerCol>
    </BaseRow>
  );
};
