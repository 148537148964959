//定义图表的类别
const openInterestArr = ['CallOI', 'PutOI']
const gexArr = ['PGex', 'NGex']
const dexArr = ['PDex', 'NDex']

//生成图表tooltip call和put的变化文字
const getItemChange = (data, text, colorSchema, subTitle) => {
    const prevStr = 'prev' + text
    const currStr = 'curr' + text
    // console.log(prevStr)
    // console.log(currStr)
    if (data[prevStr] === undefined || data[prevStr] === null || data[currStr] === undefined || data[currStr] === null) {
        return '';
    }
    let str;
    if (data[prevStr] === data[currStr]) {
        str = 'No Change';
    } else if (data[currStr] > data[prevStr]) {
        //const key = text.replace(subTitle.replace(/\s/g, ''), 'Increased');
        
        const key = text.replace(/^\S/, (c) => c.toLowerCase()) +'Increased';
        const colorIndex = colorSchema[key]
        str = '<span style="color: ' + colorIndex + '"> +' + (data['curr' + text] - data['prev' + text]).toString() + '</span>';
    } else {
        const key = text.replace(/^\S/, (c) => c.toLowerCase()) + 'Decreased';
        const colorIndex = colorSchema[key]
        str = '<span style="color: ' + colorIndex + '"> -' + (data['prev' + text] - data['curr' + text]).toString() + '</span>';
    }
    str = str.replace('--', '+').replace('-+', '-').replace('+-', '-').replace('++', '+')
    return str;

}


const getItem = (data, text, colorSchema, subTitle) => {
    const currStr = 'curr' + text
    if (data[currStr] === undefined || data[currStr] === null || data === undefined || data === null) {
        return ''
    }
    const key = text.replace(subTitle.replace(/\s/g, ''), '')
    const colorIndex = colorSchema[inProper(key)];
    const type = proper(key);
    const str = '<span style="color: ' + colorIndex + '"> ' + type + ': ' + data[currStr] + '</span>';
    return str

}

// 得到 prevCall,prevPut,currCall,currPut各自的最大值以及最大值对应的strike
const getMaxValueArray = (data, catArray) => {
    let maxValues = {
        prevCall: { value: Number.MIN_VALUE, strike: null },
        prevPut: { value: Number.MIN_VALUE, strike: null },
        currCall: { value: Number.MIN_VALUE, strike: null },
        currPut: { value: Number.MIN_VALUE, strike: null }
    };
    let prevCall = Number.MIN_VALUE
    let prevPut = Number.MIN_VALUE
    let currCall = Number.MIN_VALUE
    let currPut = Number.MIN_VALUE

    data.forEach(optionGroup => {
        const strike = parseFloat(optionGroup.strike)
        // 根据category传进来的类别指定不同的field
        prevCall = parseInt(optionGroup['prev' + catArray[0]]);
        prevPut = parseInt(optionGroup['prev' + catArray[1]]);
        currCall = parseInt(optionGroup['curr' + catArray[0]]);
        currPut = parseInt(optionGroup['curr' + catArray[1]]);
        if (prevCall > maxValues.prevCall.value) {
            maxValues.prevCall = { value: prevCall, strike: strike };
        }

        if (prevPut > maxValues.prevPut.value) {
            maxValues.prevPut = { value: prevPut, strike: strike };
        }

        if (currCall > maxValues.currCall.value) {
            maxValues.currCall = { value: currCall, strike: strike };
        }

        if (currPut > maxValues.currPut.value) {
            maxValues.currPut = { value: currPut, strike: strike };
        }
    });
    return maxValues
}

const getMaxVal = (maxValueArray) => {
    const valueArray = [maxValueArray.currCall.value,
    maxValueArray.prevCall.value, maxValueArray.currPut.value,
    maxValueArray.prevPut.value]
    return Math.round(Math.max(...valueArray) * 1);
}

const getStrikeRange = (maxValueArray) => {
    const valueArray = [maxValueArray.currCall.strike,
        maxValueArray.prevCall.strike, maxValueArray.currPut.strike,
        maxValueArray.prevPut.strike]
    return [Math.round(Math.min(...valueArray) * 1), Math.round(Math.max(...valueArray) * 1)]
}

const proper = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

const inProper = (str) => {
    return str.charAt(0).toLowerCase() + str.slice(1);
}

//重新格式化数据，功能是两天之间的对比，
//为了显示条状图的长度，选取最大值显示，通过填充不同颜色实现增减变化
const getformattedData = (data, catArray, keys) => {
    const newArray = data.map((item, index) => {
        // 查找相同索引下的最小值
        const lessOfCurrentItem1AndPrevItem1 = Math.min(item['prev' + catArray[0]], item['curr' + catArray[0]]);
        const lessOfCurrentItem2AndPrevItem2 = -Math.min(item['prev' + catArray[1]], item['curr' + catArray[1]]);
        const item1Diff = item['curr' + catArray[0]] - item['prev' + catArray[0]]
        const item2Diff = item['curr' + catArray[1]] - item['prev' + catArray[1]]

        const item1Increased = item1Diff > 0 ? item1Diff : 0
        const item1Decreased = item1Diff < 0 ? -item1Diff : 0
        const item2Increased = item2Diff > 0 ? -item2Diff : 0
        const item2Decreased = item2Diff < 0 ? item2Diff : 0

        const flag = parseInt(Math.abs(lessOfCurrentItem1AndPrevItem1)) + parseInt(Math.abs(lessOfCurrentItem2AndPrevItem2)) > 1 ? true : false;
        return {
            strike: item.strike,
            [keys[5]]: lessOfCurrentItem2AndPrevItem2,
            [keys[0]]: lessOfCurrentItem1AndPrevItem1,
            [keys[1]]: item1Increased,
            [keys[2]]: item1Decreased,
            [keys[3]]: item2Increased,
            [keys[4]]: item2Decreased,
            flag: flag,
        };
    });

    return newArray.filter(item => item.flag === true);
}
export { getItemChange, getItem, getformattedData, getMaxVal, getMaxValueArray, getStrikeRange, openInterestArr, gexArr, dexArr }