import { createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import { gql } from '@apollo/client';
import { client } from '../../graphql/apolloClient';
import { formatMsg } from '../../utils/utils'

const CREATE_USER = gql`
    mutation CreateUser($userInput: UserInput!) {
      createUser(userInput: $userInput) {
        firstName
        lastName
        email
        imgUrl
        isActivate
        __typename
    }
  }
`;
const ACTIVATE_USER = gql`
    mutation ActivateUser($verificationInput: VerificationInput!) {
      activateUser(verificationInput: $verificationInput) {
        token
        tokenExpiration
        user {
            firstName
            lastName
            email
            isActivate
        }
    }
  }
`;
const SEND_EMAIL= gql`
    mutation SendEmail($email: String!) {
      sendEmail(email: $email) {
        success
        message
        
    }
  }
`;
const RESET_PASSWORD = gql`
    mutation ResetPassword($resetPasswordInput:ResetPasswordInput!) {
      resetPassword(resetPasswordInput: $resetPasswordInput) {
        success
        message
        
    }
  }
`;
const LOGIN_USER = gql`
    query LoginUser($loginInput: LoginInput!) {
        login(loginInput: $loginInput) {
            token
            tokenExpiration
            user {
                firstName
                lastName
                email
                isActivate
            }
        }
    }
`;

const initialState = {
    signUp: {
        user: null,
        status: false,
        message: '',
        isLoading: '',
        count: 0,
    },
    signIn: {
        user: null,
        status: false,
        token: null,
        tokenExpiration: '',
        message: '',
        isLoading: '',
        count: 0,
    },
    email:{
        status: false,
        isLoading: '',
        message: '',
        count: 0,
    },
    resetPassword: {
        status: false,
        isLoading: '',
        message: '',
        count: 0,
    }

}

export const activate = createAsyncThunk('user/activate', async (verificationInput) => {
    const { data } = await client.mutate({
        mutation: ACTIVATE_USER,
        variables: { verificationInput },
    });
    let newData = {};
    const user = data.activateUser.user
    newData.signInMessage = user.isActivate === '0' ? 'Account is not activated' : 'Sign In successfully'
    newData.user = user;
    newData.token = data.activateUser.token;
    newData.tokenExpiration = data.activateUser.tokenExpiration;
    return newData;
});


export const sendEmail = createAsyncThunk('user/sendemail', async (email) => {
    const { data } = await client.mutate({
        mutation: SEND_EMAIL,
        variables: { email },
    });
    return data.sendEmail;
});

export const resetPassword = createAsyncThunk('user/resetpassword', async (resetPasswordInput) => {
    const { data } = await client.mutate({
        mutation: RESET_PASSWORD,
        variables: { resetPasswordInput },
    });
    return data.resetPassword;
});

export const signIn = createAsyncThunk('user/signIn', async (loginInput) => {
    const { data } = await client.query({
        query: LOGIN_USER,
        variables: { loginInput },
    });
    let newData = {};
    const user = data.login.user
    newData.signInMessage = user.isActivate === '0' ? 'Account is not activated' : 'Sign In successfully'
    newData.user = user;
    newData.token = data.login.token;
    newData.tokenExpiration = data.login.tokenExpiration;
    return newData;
});

export const signUp = createAsyncThunk('user/signUp', async (userInput) => {
    const { data } = await client.mutate({
        mutation: CREATE_USER,
        variables: { userInput },
    });
    let newData = {};
    newData.signUpMessage = 'Sign Up successfully';
    newData.user = data.createUser;
    return newData;
});


export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            //signUp reducer
            .addCase(signUp.pending, (state) => {
                state.signUp.isLoading = true;
                state.signUp.status = false;
            })
            .addCase(signUp.fulfilled, (state, action) => {
                state.signUp.user = action.payload.user;
                state.signUp.status = true;
                state.signUp.isLoading = false;
                state.signUp.message = action.payload.signUpMessage;
                state.signUp.count++;
            })
            .addCase(signUp.rejected, (state, action) => {
                state.signUp.message = formatMsg(action.error.message);
                state.signUp.status = false;
                state.signUp.user = action.meta.arg;
                state.signUp.isLoading = false;
                state.signUp.count++;
            })
            //signIn reducer
            .addCase(signIn.pending, (state) => {
                state.signIn.isLoading = true;
                state.signIn.status = false;
            })
            .addCase(signIn.fulfilled, (state, action) => {
                state.signIn.user = action.payload.user;
                if (!state.signIn.status) {
                    state.signIn.status = true
                }
                state.signIn.isLoading = false;
                state.signIn.message = action.payload.signInMessage;
                state.signIn.token = action.payload.token;
                state.signIn.tokenExpiration = action.payload.tokenExpiration;
                state.signIn.count++;
            })
            .addCase(signIn.rejected, (state, action) => {
                state.signIn.message = formatMsg(action.error.message);
                if (state.signIn.status) {
                    state.signIn.status = false
                }
                state.signIn.user = action.meta.arg;
                state.signIn.isLoading = false;
                state.signIn.count++;
            })
            //activate reducer
            .addCase(activate.pending, (state) => {
                state.signIn.isLoading = true;
                state.signIn.status = false;
            })
            .addCase(activate.fulfilled, (state, action) => {
                state.signIn.user = action.payload.user;
                state.signIn.status = true
                state.signIn.isLoading = false;
                state.signIn.message = action.payload.signInMessage;
                state.signIn.token = action.payload.token;
                state.signIn.tokenExpiration = action.payload.tokenExpiration;
                state.signIn.count++;
            })
            .addCase(activate.rejected, (state, action) => {
                state.signIn.message = formatMsg(action.error.message);
                if (state.signIn.status) {
                    if (state.signIn.message.includes('invalid') || state.signIn.message.includes('expired')) {
                        state.signIn.status = false
                    }
                }
                state.signIn.isLoading = false;
                state.signIn.count++;
            })
            .addCase(sendEmail.pending, (state) => {
                state.email.isLoading = true;
            })
            .addCase(sendEmail.fulfilled, (state, action) => {
                state.email.isLoading = false;
                state.email.status = action.payload.success
                state.email.message = action.payload.message;
                state.email.count++;
            })
            .addCase(sendEmail.rejected, (state, action) => {
                state.email.isLoading = false;
                state.email.status = true;
                state.email.message = "Email sent error";
                state.email.count++;
            })
            .addCase(resetPassword.pending, (state) => {
                state.resetPassword.isLoading = true;
            })
            .addCase(resetPassword.fulfilled, (state, action) => {
                console.log(action)
                state.resetPassword.isLoading = false;
                state.resetPassword.status = action.payload.success
                state.resetPassword.message = action.payload.message;
                state.resetPassword.count++;
            })
            .addCase(resetPassword.rejected, (state, action) => {
                console.log(action)
                state.resetPassword.isLoading = false;
                state.resetPassword.status = true;
                state.resetPassword.message = "Reset password error";
                state.resetPassword.count++;
            })

            ;

    },
});


export default userSlice.reducer;