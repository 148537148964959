import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { BaseForm } from '../../../components/common/form/BaseForm/BaseForm';
import { notificationController } from '../../../controllers/notificationController';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { data as t } from '../../../locale/translation'
import { resetPassword } from '../../../store/slices/userSlice';
import * as S from './NewPasswordForm.styles';
import * as Auth from '../../../layouts/AuthLayout/AuthLayout.styles';


// const initStates = {
//   password: 'new-password',
//   confirmPassword: 'new-password',
// };
const initStates = {
  password: '',
  confirmPassword: '',
};
export const NewPasswordForm= () => {

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const s = useAppSelector((state) => state.user.resetPassword)
  useEffect(() => {
    if (s.status !== undefined) {
      if (s.status) {
        notificationController.success({
          message: s.message,
        });
        navigate("/auth/login");
      } else if (s.message !== '') {
        notificationController.error({
          message: s.message,
        });
      }
    }
  }, [s.count]);


  const handleSubmit = (values) => {
    
    const queryString = window.location.search;
    // 解析查询字符串
    const urlParams = new URLSearchParams(queryString);
    // 获取特定参数值
    const linkCode = urlParams.get('linkCode');

    const resetPasswordObj = { linkCode: linkCode, ...values } 
    // setLoading(true);
    dispatch(resetPassword({ ...resetPasswordObj }))
      .unwrap()
      .catch((err) => {
      });
  };

  return (
    <Auth.FormWrapper>
      <BaseForm layout="vertical" onFinish={handleSubmit} requiredMark="optional" initialValues={initStates}>
        {/* <Auth.BackWrapper onClick={() => navigate(-1)}>
          <Auth.BackIcon />
          {t.common.back}
        </Auth.BackWrapper> */}
        <Auth.FormTitle>{t.newPassword.title}</Auth.FormTitle>
        <S.Description>{t.newPassword.description}</S.Description>
        <Auth.FormItem
          name="password"
          label={t.common.password}
          rules={[{ required: true, message: t.common.requiredField }]}
        >
          <Auth.FormInputPassword placeholder={t.common.password} />
        </Auth.FormItem>
        <Auth.FormItem
          name="confirmPassword"
          label={t.common.confirmPassword}
          dependencies={['password']}
          rules={[
            { required: true, message: t.common.requiredField },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(t.common.confirmPasswordError));
              },
            }),
          ]}
          hasFeedback
        >
          <Auth.FormInputPassword placeholder={t.common.confirmPassword} />
        </Auth.FormItem>
        <BaseForm.Item noStyle>
          <S.SubmitButton type="primary" htmlType="submit" loading={s.isLoading}>
            {t.common.resetPassword}
          </S.SubmitButton>
        </BaseForm.Item>
      </BaseForm>
    </Auth.FormWrapper>
  );
};
