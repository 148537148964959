import React from 'react';
import { LoginForm } from './LoginForm/LoginForm';
import { PageTitle } from '../../components/common/PageTitle';

const LoginPage = () => {
  return (
    <>
      <PageTitle>Log In</PageTitle>
      <LoginForm />
    </>
  );
};

export default LoginPage;