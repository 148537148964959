import React from 'react';
import { notification } from 'antd';
import styled from 'styled-components';
import {
  CheckCircleFilled,
  ExclamationCircleFilled,
  InfoCircleFilled,
  StopFilled,
} from '@ant-design/icons';

const IconWrapper = styled.div`
  font-size: ${(props) => (props.$isOnlyTitle ? '2rem' : '3rem')};
  line-height: 2rem;
`;

const EmptyDescription = styled.div`
  margin-top: -0.75rem;
`;

const NotificationWrapper = styled.div`
  .ant-notification-notice {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  }
`;

const openNotification = (type, config) => {
  const key = `notification_${Date.now()}`;
  const mergedConfig = {
    key,
    duration: 1, // 不自动关闭
    ...config,
    icon: (
      <IconWrapper $isOnlyTitle={!config.description}>
        {type === 'success' && <CheckCircleFilled className="success-icon" />}
        {type === 'info' && <InfoCircleFilled className="info-icon" />}
        {type === 'warning' && <ExclamationCircleFilled className="warning-icon" />}
        {type === 'error' && <StopFilled className="error-icon" />}
      </IconWrapper>
    ),
    message: <div className={`title ${!config.description && `title-only`}`}>{config.message}</div>,
    description: config.description ? <div className="description">{config.description}</div> : <EmptyDescription />,
    className: config.description ? '' : 'notification-without-description',
  };

  notification[type]({
    ...mergedConfig,
    getContainer: () => document.body, // 渲染到独立的容器中
  });
};

export const notificationController = {
  success: (config) => openNotification('success', config),
  info: (config) => openNotification('info', config),
  warning: (config) => openNotification('warning', config),
  error: (config) => openNotification('error', config),
};