import { ApolloClient, InMemoryCache, createHttpLink, ApolloLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { Observable } from 'rxjs';
import { notificationController } from '../controllers/notificationController'

const httpLink = createHttpLink({
    uri: '/graphql',
    //uri: 'http://192.168.2.20:8800/graphql',
});

// 自定义 ApolloLink 来添加 token
const authLink = setContext((_, { headers }) => {
    // 从 sessionStorage 获取 token
    const token = sessionStorage.getItem('accessToken');
    if (token) {
        return {
            headers: {
                ...headers,
                Authorization: token ? `Bearer ${token}` : '',
            },
        };
    } else {
        return headers;
    }
});

// 自定义 ApolloLink 添加拦截器
const customLink = new ApolloLink((operation, forward) => {
    let isSubscribed = true; // 用于追踪组件是否仍然挂载的标志

    return new Observable((observer) => {
        setTimeout(() => {
            if (isSubscribed) {
                forward(operation).subscribe({
                    next: (response) => {
                        const httpResponse = response?.response;
                        const statusCode = httpResponse?.status || response?.errors ? 400 : 200;

                        //console.log('HTTP Status Code:', statusCode);
                        const currentPath = window.location.pathname;
                        //console.log('from apollo client')

                        const error = response?.errors ? response?.errors[0].message : "";
                        if (error.includes("Token is invalid")) {
                            window.location.href = '/auth/login';
                            if (isSubscribed) {
                                setTimeout(() => {
                                    notificationController.warning({
                                        message: 'Invalid or expired token, please log in again.',
                                    });
                                }, 0); // 2 seconds delay before showing the notification
                                isSubscribed = false; // 在第一次通知后设置为 false
                            }
                        }


                        // if (statusCode === 400 && !currentPath.includes('auth')) {
                        //     //window.location.href = '/auth/login';
                        //     if (isSubscribed) {
                        //         setTimeout(() => {
                        //             notificationController.warning({
                        //                 message: 'Invalid or expired token, please log in again.',
                        //             });
                        //         }, 0); // 2 seconds delay before showing the notification
                        //         isSubscribed = false; // 在第一次通知后设置为 false
                        //     }
                        // }

                        observer.next(response);
                    },
                    error: (error) => {
                        console.error('Custom link onError:', error);
                        console.error('Error Status Code:', error?.response?.status);
                        observer.error(error);
                    },
                    complete: () => {
                        observer.complete();
                    },
                });
            }
        }, 0);

        // 清理函数，在组件卸载时取消订阅
        return () => {
            isSubscribed = false;
        };
    });
});


// 使用 Axios 处理 GraphQL 请求
export const client = new ApolloClient({
    link: ApolloLink.from([customLink, authLink, httpLink]),
    //link: ApolloLink.from([authLink, httpLink]),
    cache: new InMemoryCache(),

});
