import React, { useEffect, useRef } from 'react';
import { Navigate } from 'react-router-dom';
import { notificationController } from '../controllers/notificationController';

const RequireAuth = ({ children }) => {
  const isMounted = useRef(true);

  //const signInStatus = useAppSelector((state) => state.user.signIn.status);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const checkAuth = async () => {
      const token = sessionStorage.getItem('accessToken');
      if (!token|| token ==='null'){
        sessionStorage.removeItem('accessToken');
      }
      if (!token && isMounted.current) {
        notificationController.warning({ message: 'Please login first' });
      }
    };

    checkAuth();
  }, []); // 空数组表示只在组件挂载时执行一次


  useEffect(() => {
  }, []); 

  

const token = sessionStorage.getItem('accessToken');

// 在这里放置需要返回的 JSX
  return token  ? <>{children}</> : <Navigate to="/auth/login" replace />;
};

export default RequireAuth;


