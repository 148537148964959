import React from 'react';
import { Form } from 'antd';
import { BaseFormTitle } from '../components/BaseFormTitle/BaseFormTitle';
import { BaseFormItem } from '../components/BaseFormItem/BaseFormItem';
import { BaseFormList } from '../components/BaseFormList/BaseFormList';
// import { notificationController } from '@app/controllers/notificationController';

const BaseForm = ({ onFinishFailed, layout = 'vertical', ...props }) => {
  const onFinishFailedDefault = (error) => {
    // notificationController.error({
    //   message: 'error',
    //   description: error.errorFields[0].errors,
    // });
  };

  return React.createElement(Form, { onFinishFailed: onFinishFailed || onFinishFailedDefault, layout, ...props });
};

BaseForm.Title = BaseFormTitle;
BaseForm.Item = BaseFormItem;
BaseForm.List = BaseFormList;
BaseForm.useForm = Form.useForm;
BaseForm.Provider = Form.Provider;

export { BaseForm };
