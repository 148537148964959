import { Priority } from '../constants/enums/priorities';
import { currencies } from '../constants/config/currencies';
import visa from '../assets/images/card-issuers/visa.png';
import mastercard from '../assets/images/card-issuers/mastercard.png';
import maestro from '../assets/images/card-issuers/maestro.png';


export const camelize = (string) => {
  return string
    .split(' ')
    .map((word, index) => (index === 0 ? word.toLowerCase() : word[0].toUpperCase() + word.slice(1)))
    .join('');
};

export const getCurrencyPrice = (price, currency, isIcon = true) => {
  const currencySymbol = currencies[currency][isIcon ? 'icon' : 'text'];

  return isIcon ? `${currencySymbol}${price}` : `${price} ${currencySymbol}`;
};

const getMarkAreaData = (data) =>
  data.map((el, index) => [
    {
      xAxis: 2 * index,
    },
    {
      xAxis: 2 * index + 1,
    },
  ]);

export const capitalize = (word) => `${word[0].toUpperCase()}${word.slice(1)}`;

export const hexToRGB = (hex, defaultValue = '0, 0, 0') => {
  const hexRegex = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i;
  const result = hexRegex.exec(hex);

  if (!result) {
    // console.warn('Non valid HEX color. Using default value.');
    return defaultValue;
  }

  let [_, hexR, hexG, hexB] = result;
  let r = parseInt(hexR, 16);
  let g = parseInt(hexG, 16);
  let b = parseInt(hexB, 16);

  if (isNaN(r) || isNaN(g) || isNaN(b)) {
    // console.warn('Invalid RGB values. Using default value.');
    return defaultValue;
  }

  return `${r}, ${g}, ${b}`;
};



export const getDifference = (value, prevValue) =>
  prevValue !== 0 ? `${((Math.abs(value - prevValue) / prevValue) * 100).toFixed(0)}%` : '100%';

export const normalizeProp = (prop) =>
  typeof prop === 'number' ? `${prop}px` : (Array.isArray(prop) && `${prop[0]}px ${prop[1]}px`) || prop.toString();

export const defineColorByPriority = (priority) => {
  switch (priority) {
    case Priority.INFO:
      return 'var(--primary-color)';
    case Priority.LOW:
      return 'var(--success-color)';
    case Priority.MEDIUM:
      return 'var(--warning-color)';
    case Priority.HIGH:
      return 'var(--error-color)';
    default:
      return 'var(--success-color)';
  }
};

export const defineColorBySeverity = (severity, rgb = false) => {
  const postfix = rgb ? 'rgb-color' : 'color';
  switch (severity) {
    case 'error':
    case 'warning':
    case 'success':
      return `var(--${severity}-${postfix})`;
    case 'info':
    default:
      return `var(--primary-${postfix})`;
  }
};

export const mergeBy = (a, b, key) =>
  a.filter((elem) => !b.find((subElem) => subElem[key] === elem[key])).concat(b);

export const getSmoothRandom = (factor, start) => {
  const halfEnvelope = 1 / factor / 2;
  const max = Math.min(1, start + halfEnvelope);
  const min = Math.max(0, start - halfEnvelope);

  return Math.random() * (max - min) + min;
};

export const shadeColor = (color, percent) => {
  let R = parseInt(color.substring(1, 3), 16);
  let G = parseInt(color.substring(3, 5), 16);
  let B = parseInt(color.substring(5, 7), 16);

  R = parseInt(((R * (100 + percent)) / 100).toString());
  G = parseInt(((G * (100 + percent)) / 100).toString());
  B = parseInt(((B * (100 + percent)) / 100).toString());

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  const RR = R.toString(16).length == 1 ? '0' + R.toString(16) : R.toString(16);
  const GG = G.toString(16).length == 1 ? '0' + G.toString(16) : G.toString(16);
  const BB = B.toString(16).length == 1 ? '0' + B.toString(16) : B.toString(16);

  return '#' + RR + GG + BB;
};


export const hexToHSL = (hex, defaultHSL = '0,0%,50%') => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  if (!result) {
    // console.warn('Non valid HEX color. Using default values.');
    return parseHSL(defaultHSL);
  }

  let [_, hexR, hexG, hexB] = result;
  let r = parseInt(hexR, 16);
  let g = parseInt(hexG, 16);
  let b = parseInt(hexB, 16);

  if (isNaN(r) || isNaN(g) || isNaN(b)) {
    // console.warn('Invalid RGB values. Using default values.');
    return parseHSL(defaultHSL);
  }

  r /= 255;
  g /= 255;
  b /= 255;

  const max = Math.max(r, g, b),
    min = Math.min(r, g, b);
  let h, s;
  const l = (max + min) / 2;

  if (max === min) {
    h = s = 0; // achromatic
  } else {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
      default:
        h = 0;
    }
    h /= 6;
  }

  return {
    h: Math.round(h * 360), // Convert H to degrees
    s: Math.round(s * 100), // Convert S to percentage
    l: Math.round(l * 100), // Convert L to percentage
  };
};

// 解析 HSL 字符串
const parseHSL = (hslString) => {
  const [h, s, l] = hslString.split(',').map((val) => parseFloat(val.replace('%', '')));
  return { h, s, l };
};




export const formatNumberWithCommas = (value) => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const msToH = (ms) => Math.floor(ms / 3600000);

export const hToMS = (h) => h * 3600000;

export const getPaymentCardTypeIcon = (type) => {
  switch (type) {
    case 'visa':
      return visa;
    case 'mastercard':
      return mastercard;
    case 'maestro':
      return maestro;
    case 'amex':
      return 'amex';
    case 'discover':
      return 'discover';
    case 'diners':
      return 'diners';
    case 'jcb':
      return 'jcb';
    case 'unionpay':
      return 'unionpay';
    default:
      return null;
  }
};

export const mapBadgeStatus = (status) => {
  if (!status || status === 'default' || status === 'processing') {
    return 'info';
  }

  return status;
};

export const formatMsg = message => message.replace(/.*?:/, "").trim();
