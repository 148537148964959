import React from 'react';
import { useResponsive } from '../../../../hooks/useResponsive';
import { useAppSelector } from '../../../../hooks/reduxHooks';
import * as S from './OIChangeTable.styles';
import { Table } from 'antd';

export const OIChangeTable = ({ activeItem, setActiveItem, data, oiType }) => {
  const { isTablet, isDesktop } = useResponsive();
  const dataWithKey = data.map((item, index) => { return { key: index, ...item } })
  const types = [...new Set(data.map(item => item.type))].map((type, index) => ({
    key: `type-${index}`, // 为每个类型生成一个唯一的 key
    type,
  }));

  // 外层的表格，展示 type 分类
  const outerColumns = [
    Table.EXPAND_COLUMN,
    {
      title: 'Symbol Type',
      dataIndex: 'type',
      key: 'type',
      render: (type) => <S.Text>{type}</S.Text>,
    }
  ];

  // 内层表格展示明细
  const innerColumns = [
    {
      title: 'SYM',
      dataIndex: 'symbol',
      render: (symbol, record) => (
        <S.Text $isActive={activeItem.key === record.key}>{symbol}</S.Text>
      ),
    },
    {
      title: 'STR',
      dataIndex: 'strike',
      render: (strike, record) => (
        <S.Text $isActive={activeItem.key === record.key}>{strike}</S.Text>
      ),
    },
    {
      title: 'EXP',
      dataIndex: 'expDate',
      render: (expiryDate, record) => {
        const [year, month, day] = expiryDate.split('-');
        return (
          <S.Text $isActive={activeItem.key === record.key}>
            {year}<br />{month}-{day}
          </S.Text>
        );
      },
    },
    {
      title: 'POI',
      dataIndex: 'prevOi',
      render: (prevOi, record) => (
        <S.Text $isActive={activeItem.key === record.key}>{prevOi}</S.Text>
      ),
    },
    {
      title: 'COI',
      dataIndex: 'currOi',
      render: (currOi, record) => (
        <S.Text $isActive={activeItem.key === record.key}>{currOi}</S.Text>
      ),
    },
    {
      title: 'CHG',
      dataIndex: 'oiChange',
      render: (oiChange, record) => {
        const color = oiType === 'Call INCR' || oiType === 'Put INCR' ? 'green' : 'red';
        return (
          <S.Text $isActive={activeItem.key === record.key} style={{ color }}>
            {oiChange}
          </S.Text>
        );
      },
    },
    {
      title: 'PCT',
      dataIndex: 'oiChangePct',
      render: (oiChangePct, record) => {
        const color = oiType === 'Call INCR' || oiType === 'Put INCR' ? 'green' : 'red';
        return (
          <S.Text $isActive={activeItem.key === record.key} style={{ color }}>
            {oiChangePct}
          </S.Text>
        );
      },
    }
  ];

  return (
    <S.Table
      size={'small'}
      pagination={false}
      columns={outerColumns}
      dataSource={types}
      scroll={{ y: isDesktop ? 600 : isTablet ? 620 : 400 }}
      expandable={{
        expandedRowRender: (record) =>
          <S.Table
            columns={innerColumns}
            dataSource={dataWithKey.filter(item => item.type === record.type)} // 使用过滤后的数据源
            pagination={false}
            rowKey={(item) => item.key} // 使用唯一的 key
          />
      }}
      rowKey="key" // 使用 item 的唯一标识符
    />
  );
};