const OILightColorSchema = {
    'callOI': '#c6775f',
    'callOIIncreased': '#9db19c',
    'callOIDecreased': '#b58587',
    'putOIIncreased': '#94ab8d',
    'putOIDecreased': '#b08385',
    'putOI': '#7cacbc',
    
}

const GEXLightColorSchema = {
    'pGex': '#c6775f',
    'pGexIncreased': '#9db19c',
    'pGexDecreased': '#b58587',
    'nGexIncreased': '#94ab8d',
    'nGexDecreased': '#b08385',
    'nGex': '#7cacbc' 
}

const DEXLightColorSchema = {
    'positiveDex': '#c6775f',
    'positiveDeXIncreased': '#9db19c',
    'positiveDeXDecreased': '#b58587',
    'negativeDeXIncreased': '#94ab8d',
    'negativeDeXDecreased': '#b08385',
    'negativeDex': '#7cacbc'
}

const dictSchema = {
    oiLight: OILightColorSchema,
    gexLight: GEXLightColorSchema,
    dexLight: DEXLightColorSchema,
}



export default dictSchema;



