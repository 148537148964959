import React from 'react';
import { Avatar } from 'antd';

const getColorForLetter = (letter) => {
  const colorMap = {
    A: '#FF5733',
    B: '#33FF57',
    C: '#5733FF',
    D: '#FF33A6',
    E: '#33A6FF',
    F: '#A6FF33',
    G: '#FF3366',
    H: '#3366FF',
    I: '#66FF33',
    J: '#FF6633',
    K: '#33FF66',
    L: '#6633FF',
    M: '#FFCC33',
    N: '#CC33FF',
    O: '#33FFCC',
    P: '#FF9966',
    Q: '#9966FF',
    R: '#FF9933',
    S: '#3399FF',
    T: '#FF3399',
    U: '#99FF33',
    V: '#FFCC00',
    W: '#00CCFF',
    X: '#FF3300',
    Y: '#00FFCC',
    Z: '#FF33A6',
  };
  const uppercaseLetter = letter.toUpperCase();
  return colorMap[uppercaseLetter] || '#000000';
}


export const BaseAvatar = (props) => {
  const { usr } = props;
  const firstInitial = usr.firstName.charAt(0).toUpperCase();
  const lastInitial = usr.lastName.charAt(0).toUpperCase();
  const name = `${firstInitial}${lastInitial}`;
  const avatarStyle = {
    backgroundColor: getColorForLetter(name.charAt(0)),
    letterSpacing: '1.5px', 
  };
  return <Avatar {...props} style={avatarStyle}>{name}</Avatar>;
};

