import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { BaseForm } from '../../../components/common/form/BaseForm/BaseForm';
import * as S from './ForgotPasswordForm.styles';
import * as Auth from '../../../layouts/AuthLayout/AuthLayout.styles';
import { data as t } from '../../../locale/translation'
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { notificationController } from '../../../controllers/notificationController';
import { sendEmail } from '../../../store/slices/userSlice';

const initValues = {
  email: 'joe.shanhenian@gmail.com',
};

export const ForgotPasswordForm = () => {
  const navigate = useNavigate();
   const location = useLocation();
  const dispatch = useAppDispatch();
  const s = useAppSelector((state) => state.user.email)

  useEffect(() => {
    if (s.status !== undefined) {
      if (s.status) {
        notificationController.success({
          message: s.message,
        });
        navigate('/auth/login');
      } else {
        if (s.message!=''){
          notificationController.warning({
            message: s.message,
          });
        }
        
      }

    }
  }, [s.count]);


  const handleSubmit = (values) => {
    dispatch(sendEmail(values.email))
      .unwrap()
      .catch((err) => {
      });
  };

  const shouldShowBackButton = () => {
    // 如果上一个路径是 '/auth/login'，则返回 true，否则返回 false
    return location.state?.from === '/auth/login';
  };

  return (
    <Auth.FormWrapper>
      <BaseForm layout="vertical" onFinish={handleSubmit} requiredMark="optional" initialValues={initValues}>
        {shouldShowBackButton() && (
          <Auth.BackWrapper onClick={() => navigate(-1)}>
            <Auth.BackIcon />
            {t.common.back}
          </Auth.BackWrapper>
        )}
        <Auth.FormTitle>{t.common.resetPassword}</Auth.FormTitle>
        <S.Description>{t.forgotPassword.description}</S.Description>
        <Auth.FormItem
          name="email"
          label={t.common.email}
          rules={[{ required: true, message: t.common.emailError }]}
        >
          <Auth.FormInput placeholder={t.common.email} />
        </Auth.FormItem>
        <BaseForm.Item noStyle>
          <S.SubmitButton type="primary" htmlType="submit" loading={s.isLoading}>
            Send Email
          </S.SubmitButton>
        </BaseForm.Item>
      </BaseForm>
    </Auth.FormWrapper>
  );
};
