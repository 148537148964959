import React from 'react';
import styled, { css } from 'styled-components';
import ReactCountryFlag from 'react-country-flag';
import { useAppSelector } from '../../../../../hooks/reduxHooks';


export const CategoryItem = (item) => {
    const theme = 'light';

    return (

        <div>
            <StyledDiv className="exchange-site-text">
                {item.exchangeSite}
                <StyledFlag svg countryCode={item.country} />
            </StyledDiv>
            <CatStyledDiv theme={theme} className="category-text">{item.category}</CatStyledDiv>
        </div>



    )
}


const StyledDiv = styled.div`
  font-size: 0.75rem;
`;

const CatStyledDiv = styled.div`
${props => // 使用 props
        props.theme === 'light' ?
            css`
        color:#2983D8;
        font-size: 0.75rem;
        text-align: right;   
    `
            : css`
        color:#d6f1ff;
        font-size: 0.75rem;
        text-align: right;   
    `}
`;

const StyledFlag = styled(ReactCountryFlag)`
    margin-left: 0.2rem;
    font-size: 1.2rem;
    border-radius: 50 %;
    object-fit: cover;
`;

// #d6f1ff