import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { BaseForm } from '../../../components/common/form/BaseForm/BaseForm';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { signUp } from '../../../store/slices/userSlice';
import { notificationController } from '../../../controllers/notificationController';
import { ReactComponent as FacebookIcon } from '../../../assets/icons/facebook.svg';
import { ReactComponent as GoogleIcon } from '../../../assets/icons/google.svg';
import * as Auth from '../../../layouts/AuthLayout/AuthLayout.styles';
import * as S from './SignUpForm.styles.js';
import { data as t } from '../../../locale/translation'


// const initValues = {
//   firstName: 'Tom',
//   lastName: 'Jerry',
//   email: 'joe.shanhenian@gmail.com',
//   password: 'password',
//   confirmPassword: 'password',
//   termOfUse: true,
// };

const initValues = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  confirmPassword: '',
  termOfUse: false,
};

export const SignUpForm = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const s = useAppSelector((state) => state.user.signUp)

  useEffect(() => {
    if (s.status !== undefined) {
      if (s.status) {
        notificationController.success({
          message: s.message,
          description: s.message,
        });
         navigate("/auth/security-code", {
           state: { from: window.location.pathname },
         });
      } else if (s.message !== '') {
        notificationController.error({
          message: s.message,
          description: s.message,
        });
      }
    }
  }, [s.count]);

  const handleSubmit = (values) => {
    const userInput = {}
    userInput.email = values.email
    userInput.firstName = values.firstName
    userInput.lastName = values.lastName
    userInput.password = values.password
    userInput.imgUrl = "https://example.com/path/to/image.jpg"

    dispatch(signUp(userInput))
      .unwrap()
      .catch((err) => {
        // notificationController.error({ message: err.message });
        //setLoading(false);
      });
  };

  return (
    <Auth.FormWrapper>
      <BaseForm layout="vertical" onFinish={handleSubmit} requiredMark="optional" initialValues={initValues}>
        <S.Title>{t.common.signUp}</S.Title>
        <Auth.FormItem
          name="firstName"
          label={t.common.firstName}
          rules={[{ required: true, message: t.common.requiredField }]}
        >
          <Auth.FormInput placeholder={t.common.firstName} />
        </Auth.FormItem>
        <Auth.FormItem
          name="lastName"
          label={t.common.lastName}
          rules={[{ required: true, message: t.common.requiredField }]}
        >
          <Auth.FormInput placeholder={t.common.lastName} />
        </Auth.FormItem>
        <Auth.FormItem
          name="email"
          label={t.common.email}
          rules={[
            { required: true, message: t.common.requiredField },
            {
              type: 'email',
              message: t.common.notValidEmail,
            },
          ]}
        >
          <Auth.FormInput placeholder={t.common.email} />
        </Auth.FormItem>
        <Auth.FormItem
          label={t.common.password}
          name="password"
          rules={[{ required: true, message: t.common.requiredField }]}
        >
          <Auth.FormInputPassword placeholder={t.common.password} />
        </Auth.FormItem>
        <Auth.FormItem
          label={t.common.confirmPassword}
          name="confirmPassword"
          dependencies={['password']}
          rules={[
            { required: true, message: t.common.requiredField },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(t.common.confirmPasswordError));
              },
            }),
          ]}
        >
          <Auth.FormInputPassword placeholder={t.common.confirmPassword} />
        </Auth.FormItem>
        <Auth.ActionsWrapper>
          <BaseForm.Item name="termOfUse" valuePropName="checked" noStyle>
            <Auth.FormCheckbox>
              <Auth.Text>
                {t.signup.agree}{' '}
                <Link to="/" target={'_blank'}>
                  <Auth.LinkText>{t.signup.termOfUse}</Auth.LinkText>
                </Link>{' '}
                and{' '}
                <Link to="/" target={'_blank'}>
                  <Auth.LinkText>{t.signup.privacyOPolicy}</Auth.LinkText>
                </Link>
              </Auth.Text>
            </Auth.FormCheckbox>
          </BaseForm.Item>
        </Auth.ActionsWrapper>
        <BaseForm.Item noStyle>
          <Auth.SubmitButton type="primary" htmlType="submit" loading={s.isLoading}>
            {t.common.signUp}
          </Auth.SubmitButton>
        </BaseForm.Item>
        {/* <BaseForm.Item noStyle>
          <Auth.SocialButton type="default" htmlType="submit">
            <Auth.SocialIconWrapper>
              <GoogleIcon />
            </Auth.SocialIconWrapper>
            {t.signup.googleLink}
          </Auth.SocialButton>
        </BaseForm.Item>
        <BaseForm.Item noStyle>
          <Auth.SocialButton type="default" htmlType="submit">
            <Auth.SocialIconWrapper>
              <FacebookIcon />
            </Auth.SocialIconWrapper>
            {t.signup.facebookLink}
          </Auth.SocialButton>
        </BaseForm.Item> */}
        <Auth.FooterWrapper>
          <Auth.Text>
            {t.signup.alreadyHaveAccount}{' '}
            <Link to="/auth/login">
              <Auth.LinkText>{t.common.here}</Auth.LinkText>
            </Link>
          </Auth.Text>
        </Auth.FooterWrapper>
      </BaseForm>
    </Auth.FormWrapper>
  );
};
