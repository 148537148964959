import { ConfigProvider } from 'antd';
import { HelmetProvider } from 'react-helmet-async';
import enUS from 'antd/lib/locale/en_US';
import GlobalStyle from './styles/GlobalStyle';
import { AppRouter } from './router/AppRouter';
import { themeObject } from './styles/themes/themeVariables';
import 'typeface-montserrat';
import 'typeface-lato';


const App = () => {
  const theme = 'light'
  return (
    <>
      <meta name="theme-color" content={themeObject[theme].primary} />
      <GlobalStyle />
      <HelmetProvider>
      <ConfigProvider locale={enUS}>
        <AppRouter />
      </ConfigProvider>
      </HelmetProvider>
    </>
  );
};

export default App;
