import { configureStore } from '@reduxjs/toolkit';
import { combineReducers} from 'redux';
//import { errorLoggingMiddleware } from '@app/store/middlewares/errorLogging.middleware';

import optionReducer from './slices/optionSlice';
import userReducer from './slices/userSlice';

const rootReducer = combineReducers({
  option: optionReducer,
  user: userReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  //middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(navigateMiddleware),
});

