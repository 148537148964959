import React from 'react';
import { FaXTwitter as TwitterOutlined } from "react-icons/fa6";
import styled from 'styled-components';
import { BASE_COLORS } from '../../../../../styles/themes/constants';
import { BaseButton as BaseButton } from '../../../base/BaseButton/BaseButton';

export const TwitterButton = (props) => {
  const theme='light'
  return (
    <Button
      type="default"
      href="https://twitter.com/Steven_Chu1975"
      icon={<TwitterIcon />}
      target="_blank"
      $isDark={theme === 'dark'}
      {...props}
    >
      Twitter
    </Button>
  );
};

const Button = styled(BaseButton)`
  color: ${(props) => BASE_COLORS[props.$isDark ? 'white' : 'black']};
  background: ${(props) => BASE_COLORS[props.$isDark ? 'black' : 'white']};
  border-radius: 50px;
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
  align-items: center;

  &:hover,
  &:active,
  &:focus {
    color: ${(props) => BASE_COLORS[props.$isDark ? 'black' : 'white']};
    background: ${(props) => BASE_COLORS[props.$isDark ? 'white' : 'black']};
  }
`;

const TwitterIcon = styled(TwitterOutlined)`
  font-size: 1.5rem;
  vertical-align: middle;
`;
