import React from 'react';
import { Link } from 'react-router-dom';
import * as S from './ProfileOverlay.styles';
import { data as t } from '../../../../../../locale/translation'

export const ProfileOverlay = ({ ...props }) => {
  return (
    <div {...props}>
      {/* <S.Text>
        <Link to="/profile">{t.profile.title}</Link>
      </S.Text> */}
      <S.ItemsDivider />
      <S.Text>
        <Link to="/logout">{t.header.logout}</Link>
      </S.Text>
    </div>
  );
};
