import React from 'react';
import { ProfileOverlay } from '../ProfileOverlay/ProfileOverlay';
import { useResponsive } from '../../../../../../hooks/useResponsive';
import * as S from './ProfileDropdown.styles';
import { BasePopover } from '../../../../base/BasePopover/BasePopover';
import { BaseCol } from '../../../../base/BaseCol/BaseCol';
import { BaseRow } from '../../../../base/BaseRow/BaseRow';
import { BaseAvatar } from '../../../../base/BaseAvatar/BaseAvatar';

export const ProfileDropdown = () => {
  const { isTablet } = useResponsive();
  const firstName = sessionStorage.getItem('firstName')
  const lastName = sessionStorage.getItem('lastName')
  const user = {
    firstName: firstName||'Unknown',
    lastName: lastName || 'Unknown',
    imgUrl: ''
  }
  return user ? (
    <BasePopover content={<ProfileOverlay />} trigger="click">
      <S.ProfileDropdownHeader as={BaseRow} gutter={[10, 10]} align="middle">
        <BaseCol>
          <BaseAvatar src={user.imgUrl} alt="User" shape="circle" size={40} usr = { user }/>
        </BaseCol>
        {isTablet && (
          <BaseCol>
            <S.NameSpan>{`${user.firstName} ${user.lastName[0]}`}</S.NameSpan>
          </BaseCol>
        )}
      </S.ProfileDropdownHeader>
    </BasePopover>
  ) : null;
};
