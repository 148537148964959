import React from 'react';
import { PageTitle } from '../../components/common/PageTitle';
import { ForgotPasswordForm } from './ForgotPasswordForm/ForgotPasswordForm';
import { data as t } from '../../locale/translation'

const ForgotPasswordPage = () => {
  return (
    <>
      <PageTitle>{t.common.forgotPass}</PageTitle>
      <ForgotPasswordForm />
    </>
  );
};

export default ForgotPasswordPage;
