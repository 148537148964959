import React, {  useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import { Loading } from '../Loading';
import { themeObject } from '../../../styles/themes/themeVariables';
import { BORDER_RADIUS } from '../../../styles/themes/constants';


export const getChartColors = (theme)=> [
  theme.chartColor1,
  theme.chartColor2,
  theme.chartColor3,
  theme.chartColor4,
  theme.chartColor5,
];

export const getDefaultTooltipStyles = (theme) => ({
  borderColor: theme.chartColor1,
  borderWidth: 2,
  borderRadius: Number.parseInt(BORDER_RADIUS),
  textStyle: {
    fontWeight: 600,
    fontSize: 16,
    color: theme.chartColor1,
  },
});

export const BaseChart= ({ option, width, height, onEvents, style, ...props }) => {
  const theme = 'light';
  const [loading, setLoading] = useState(true);

  const chartHeight = height || '400px';

  const defaultOption = {
    color: getChartColors(themeObject[theme]),
  };

  useEffect(() => {
    // TODO FIXME workaround to make sure that parent container is initialized before the chart
    setTimeout(() => {
      setLoading(false);
    }, 1000 / 2);
  }, []);

  return loading ? (
    <Loading />
  ) : (
    <ReactECharts
      {...props}
      option={{ ...defaultOption, ...option }}
      style={{ ...style, height: chartHeight, minHeight: height === '100%' ? 400 : 'unset', width, zIndex: 0 }}
      onEvents={onEvents}
    />
  );
};
