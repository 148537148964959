import React from 'react';
import { Button as AntdButton } from 'antd';
import * as S from './BaseButton.styles';

export const { Group } = AntdButton;

export const BaseButton = React.forwardRef(
  ({ className, severity, noStyle, children, ...props }, ref) => (
    <S.Button ref={ref} className={className} $noStyle={noStyle} {...props} $severity={severity}>
      {children}
    </S.Button>
  ),
);
