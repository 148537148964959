import React from 'react';
import styled from 'styled-components';

export const SymbolItem= (item) => {

    const svg = 'https://s3-symbol-logo.tradingview.com/' + item.iconName + '.svg';

    return (
        // <div style={{ display: 'flex', alignItems: 'center' }}>
        <FlexContainer>
            {/* <div className="symbol-img"> */}
                <StyledImage src={svg} alt="Logo" />
            {/* </div> */}
            <div>
                <div className="symbol-text">{item.symbol}</div>
                <StyledDiv className="description-text">{item.description}</StyledDiv>
            </div>
        </FlexContainer>
    )
}


const StyledImage = styled.img`
  width: 2rem;
  height: 2rem;
  border-radius: 50%; 
  margin-right:0.5rem;/* 将边框半径设置为50%以创建圆形 */
`;

const StyledDiv = styled.div`
  font-size: 0.75rem;
`;
const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`;