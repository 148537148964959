import { useEffect, useRef } from 'react';
import { Chart } from '@antv/g2';
import { Loading } from '../common/Loading';
import dictSchema from '../../color-schema/color'
import { getItem, getformattedData, getItemChange } from '../../adaptor/data'
import { useAppSelector } from '../../hooks/reduxHooks'

const DivergingStackedBarChart = ({
    expiryDate, periodType, category
}) => {
    const fontFamily = 'Montserrat, sans-serif';
    const chartContainer = useRef(null);
    const s = useAppSelector((state) => state.option.options)
    const optionGroups = s.dict[expiryDate + '_' + periodType].slice().sort((a, b) => parseFloat(b.strike) - parseFloat(a.strike))//.filter(item => item.strike >= 200 && item.strike <= 300 )
    //const periodType = s.dict[expiryDate][0].periodType;
    const graphicParam = s.graphicParams.filter(item => item.expiryDate === expiryDate && item.periodType === periodType)[0]
    const symbol = useAppSelector((state) => state.option.symbol);
    const symbolAttribute = useAppSelector((state) => state.option.symbols.list.filter(item => item.symbol === symbol)[0]);
    //const price = symbolAttribute.price.last;

    const isLoading = s.isLoading;
    const width = 800;
    
    let scopedOptionGroups = null
    let maxVal = -1

    let subTitle;
    let colorSchema;
    let catArray;
    let currDate = optionGroups.length > 0 ? optionGroups[0].currDate : 'null';
    // 定义图表类型，将共同部分抽取出来封装成对象函数
    let updatedStrikeRange;




    // if (price > graphicParam.openInterestStrikeRange[1]){
    //     updatedStrikeRange[1] = parseInt(price);
    // }
    // if (price < graphicParam.openInterestStrikeRange[0]) {
    //     updatedStrikeRange[0] = parseInt(price);
    // }

    switch (true) {
        case category === 'OpenInterest':
            subTitle = 'Open Interest'
            colorSchema = dictSchema['oiLight'];
            catArray = ['CallOI', 'PutOI'];
            updatedStrikeRange = [...graphicParam.openInterestStrikeRange];
            if (symbol === 'SPX') {
                scopedOptionGroups = optionGroups.filter(item => item.strike >= (updatedStrikeRange[0] - 100) && item.strike <= (updatedStrikeRange[1] + 100))
            } else {
                scopedOptionGroups = optionGroups.filter(item => item.strike >= updatedStrikeRange[0] * 0.9 && item.strike <= updatedStrikeRange[1] * 1.1)
            }
            maxVal = graphicParam.openInterestMax
            break;
        case category === 'Gex':
            subTitle = 'GEX'
            colorSchema = dictSchema['gexLight'];
            catArray = ['PGex', 'NGex'];
            updatedStrikeRange = [...graphicParam.gexStrikeRange];
            if (symbol === 'SPX') {
                scopedOptionGroups = optionGroups.filter(item => item.strike >= (updatedStrikeRange[0] - 100) && item.strike <= (updatedStrikeRange[1] + 100))
            } else {
                scopedOptionGroups = optionGroups.filter(item => item.strike >= updatedStrikeRange[0] * 0.9 && item.strike <= updatedStrikeRange[1] * 1.1)
            }

            maxVal = graphicParam.gexMax
            break;
        case category === 'Dex':
            subTitle = 'DEX'
            colorSchema = dictSchema['dexLight'];
            catArray = ['PDex', 'NDex'];
            updatedStrikeRange = [...graphicParam.dexStrikeRange];
            if (symbol === 'SPX') {
                scopedOptionGroups = optionGroups.filter(item => item.strike >= (updatedStrikeRange[0] - 100) && item.strike <= (updatedStrikeRange[1] + 100))
            } else {
                scopedOptionGroups = optionGroups.filter(item => item.strike >= updatedStrikeRange[0] * 0.9 && item.strike <= updatedStrikeRange[1] * 1.1)
            }

            maxVal = graphicParam.dexMax
            break;
        default:

    }
    subTitle = subTitle + '\n\nLast updated: ' + currDate
    //获得颜色信息里面的key
    const keys = Object.keys(colorSchema);
    // 然后得到里面的第一个和最后一个作为item的关键字比如call和put
    const firstKey = keys[0];
    const lastKey = keys[keys.length - 1];

    const proper = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    const isDisplay = maxVal > 0 ? true : false;

    const newArray = getformattedData(scopedOptionGroups, catArray, keys);

    const height = (scopedOptionGroups.length / 100) * 800 + 200;
    // console.log(symbol + ' ' + expiryDate + ' ' + periodType + ' size: ' + scopedOptionGroups.length)
    // console.log(symbol + ' ' + expiryDate + ' ' + periodType + ' height: ' + height)

    useEffect(() => {
        if (isDisplay) {
            const chart = new Chart({
                container: chartContainer.current,
                paddingLeft: 25,
                width: width,
                height: height,

            });

            chart.title({
                title: symbol + ' ' + expiryDate + ' ' + periodType,
                //title: `${symbol} ${expiryDate} ${periodType}<br>Last updated: ${currDate}`,
                subtitle: subTitle,
                align: 'center',
                titleFontFamily: fontFamily,
                subtitleFontFamily: fontFamily,
                subtitleFontWeight: 'bold',
                titleFontWeight: 'bold',
            })
            // 使用注释添加文本
            // chart.annotation().text({
            //     position: ['50%', '95%'], // 确保位置设置合理
            //     content: '第三个标题或附加信息',
            //     style: {
            //         textAlign: 'center',
            //         fontSize: 14,
            //         fontWeight: 'bold',
            //         fill: '#333', // 文字颜色
            //     },
            // });

            //    .style('align', 'center');
            chart.coordinate({ transform: [{ type: 'transpose' }] });

            chart
                .interval()
                .data({
                    type: 'inline',
                    value: newArray,
                    transform: [
                        {
                            type: 'fold',
                            fields: Object.keys(colorSchema),
                        },
                    ],
                })
                .transform([{ type: 'stackY' }])
                .encode('x', 'strike')
                .encode('y', 'value')
                .encode('color', 'key')
                .scale('x', { padding: 0.2 })
                .scale('y', { domainMin: -maxVal, domainMax: maxVal })
                .scale('color', {
                    domain: Object.keys(colorSchema),
                    range: Object.values(colorSchema),

                })
                .legend(false)
                //.legend('color', { title: false })
                // .label({
                //     text: 'value',
                //     position: 'inside',
                //     //formatter: (v) => (v ? `${v}%` : ''),
                //     transform: [{ type: 'overlapDodgeY' }],
                //     fill: '#0F0F0F',
                //     fontSize: 8,
                // })
                .axis('y', {
                    //position: 'buttom',
                    title: false,
                    labelFontSize: 12,
                    labelFontFamily: fontFamily,

                })
                .axis('x', {
                    title: false,
                    tick: false,
                    labelFontSize: 10,
                    labelFontFamily: fontFamily,
                    labelAutoHide: true,
                    labelFormatter: (text) => {
                        // 在这里自定义格式化文本的逻辑，去掉小数点后的部分
                        return Math.floor(text).toString();
                    },
                    //labelFontWeight:2,
                    //labelFillOpacity:19,

                    //labelFill: 'red'
                })
                //.axis('x', true)

                .style('radius', 6)
                // .tooltip({
                //     showMarkers: false, // 隐藏tooltip的marker
                //     showCrosshairs: false, // 隐藏tooltip的辅助线
                // })
                .interaction('tooltip', {
                    showTitle: true, // 是否显示标题
                    shared: true, // 启用共享 tooltip
                    // 定义 tooltip 的模板
                    render: (event, { title, items }) => {
                        if (items.length === 4) {
                            return document.createElement('div');
                        }

                        const container = document.createElement('div');
                        container.style.fontFamily = fontFamily;

                        // 添加标题
                        const titleDiv = document.createElement('div');
                        titleDiv.innerHTML = `<h3 style="padding:0.5rem ;margin:0;font-weight:bold">Strike: ${title}</h3>`;
                        container.appendChild(titleDiv);

                        const itemData = optionGroups.filter(item => item.strike === title)[0];



                        // 创建表格
                        const table = document.createElement('table');
                        table.style.borderCollapse = 'collapse';
                        table.style.width = '100%';

                        // 添加call信息
                        const callRow = document.createElement('tr');
                        callRow.innerHTML = `<td style = "border: 0px solid #000; padding: 0.5rem;">${getItem(itemData, catArray[0], colorSchema, subTitle)}</td>`;
                        callRow.innerHTML += `<td style="border: 0px solid #000; padding: 0.5rem;">${getItemChange(itemData, catArray[0], colorSchema, subTitle)}</td>`
                        table.appendChild(callRow);

                        // 添加put信息
                        const putRow = document.createElement('tr');
                        putRow.innerHTML = `<td style="border: 0px solid #000; padding: 0.5rem;">${getItem(itemData, catArray[1], colorSchema, subTitle)}</td>`;
                        putRow.innerHTML += `<td style="border: 0px solid #000; padding: 0.5rem;">${getItemChange(itemData, catArray[1], colorSchema, subTitle)}</td>`;
                        table.appendChild(putRow);

                        container.appendChild(table);

                        // 添加call和put数据
                        items.forEach((item) => {
                            if (item.name.includes(proper(firstKey)) || item.name.includes(proper(lastKey))) {
                                const row = document.createElement('tr');
                                row.innerHTML = `<td style="border: 0px solid #000; padding: 0.5rem;">${item.name}</td>`;
                                row.innerHTML += `<td style="border: 0px solid #000; padding: 0.5rem;">${item.value}</td>`;
                                table.appendChild(row);
                            }
                        });

                        return container;
                    },

                })

            chart
                .lineY()
                .data([0])
                .style('lineWidth', 2)
                .style('stroke', '#fff')
                .style('strokeOpacity', 1);

            chart.call(title, width * 0.25, '1%', lastKey, colorSchema[lastKey]);
            chart.call(title, width * 0.75, '1%', firstKey, colorSchema[firstKey]);

            function title(node, x, y, text, fill) {
                node
                    .text()
                    .style('x', x)
                    .style('y', y)
                    .style('text', text)
                    .style('fontWeight', 'normal')
                    .style('dy', -10)
                    .style('fill', fill)
                    .style(fontFamily)
                    //.style('margin', '0.3125rem') /* 设置 margin，0.3125rem 相当于 5px */
                    .style('fontSize', '1.25rem'); /* 设置 fontSize，1.25rem 相当于 20px */
            }

            chart.render();

            return () => {
                chart.destroy();
            };
        }

    }, []);

    return (
        isLoading ? (
            <Loading />
        ) : (
            <div>
                <div ref={chartContainer} />
            </div>
        )
    );

};

export default DivergingStackedBarChart