import React from 'react';
import { PageTitle } from '../../components/common/PageTitle';
import { SignUpForm } from './SignUpForm/SignUpForm';
import { data as t } from '../../locale/translation'

const SignUpPage = () => {
  return (
    <>
      <PageTitle>{t.common.signUp}</PageTitle>
      <SignUpForm />
    </>
  );
};

export default SignUpPage;
