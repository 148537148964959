import React from 'react';
import { useResponsive } from '../../../../hooks/useResponsive';
import { useAppSelector } from '../../../../hooks/reduxHooks';
import * as S from './OptionSummaryTable.styles';


export const OptionSummaryTable = ({ activeItem, setActiveItem }) => {
  const { isTablet, isDesktop } = useResponsive();
  const optionSummary = useAppSelector((state) => state.option.optionSummaries.list);
  const results = optionSummary.map((item, index) =>
  ({
    key: index,
    expiryDate: item.expDate,
    periodType: item.periodType,
    pgoi: item.pgoi,
    ngoi: item.ngoi,
    hoi: item.hoiLevel,
    callResistance: item.callRes,
    putSupport: item.putSupp,
  }))
    .map(({ pgoi, ...rest }) => ({
      ...rest,
      pgoi,
      yearData: Array(12)
        .fill(null)
        .map(() => parseInt(pgoi) * Math.abs(Math.sin(Math.random() * parseInt(pgoi)))),
    }))


  //console.log(results)
  //const [dataSource] = useState < any > (results);

  const columns = [
    {
      title: 'Expiry Date',
      dataIndex: 'expiryDate',
      width: '20%',
      render: (expiryDate, { key }) => {
        const [year, month, day] = expiryDate.split('-');

        return <S.Text $isActive={activeItem.key === key}>
          {year}<br />{month}-{day}
        </S.Text>
      },
    },
    {
      title: 'Period Type',
      dataIndex: 'periodType',
      width: '15%',
      render: (periodType, { key }) => <S.Text $isActive={activeItem.key === key}>{periodType}</S.Text>,
    },
    {
      title: 'PGOI',
      dataIndex: 'pgoi',
      render: (pgoi, { key }) => <S.Text $isActive={activeItem.key === key}>{pgoi}</S.Text>,
    },
    {
      title: 'NGOI',
      dataIndex: 'ngoi',
      render: (ngoi, { key }) => <S.Text $isActive={activeItem.key === key}>{ngoi}</S.Text>,
    },
    {
      title: 'HVL',
      dataIndex: 'hoi',
      render: (hoi, { key }) => <S.Text $isActive={activeItem.key === key}>{hoi}</S.Text>,
    },
    {
      title: 'CALL RES.',
      dataIndex: 'callResistance',
      render: (callResistance, { key }) => <S.Text $isActive={activeItem.key === key}>{callResistance}</S.Text>,
    },
    {
      title: 'PUT SUPP.',
      dataIndex: 'putSupport',
      render: (putSupport, { key }) => <S.Text $isActive={activeItem.key === key}>{putSupport}</S.Text>,
    },
  ];

  return (
    <S.Table
      size={'small'}
      pagination={false}
      columns={columns}
      dataSource={results}
      scroll={{ y: isDesktop ? 600 : isTablet ? 620 : 400 }}
      onRow={(record) => {
        return {
          onClick: () => setActiveItem(record),
        };
      }}
    />
  );
};
