import React, { useState, useEffect } from 'react';
import { OptionSummaryChart } from '../OptionSummaryChart/OptionSummaryChart';
import { OptionSummaryTable } from '../OptionSummaryTable/OptionSummaryTable';
import { Loading } from '../../../common/Loading';
import { useResponsive } from '../../../../hooks/useResponsive';
import { results } from '../../../../constants/bloodTestResults';
import * as S from './OptionSummaryCard.styles';
import { useAppSelector } from '../../../../hooks/reduxHooks'

export const OptionSummaryCard = () => {

  const [activeItem, setActiveItem] = useState(results[0]);

  const { mobileOnly } = useResponsive();
  const s = useAppSelector((state) => state.option.optionSummaries)
  useEffect(() => {
  }, [s.count]);


  return (
    s.isLoading ? (
      <Loading />
    ) : (
      <S.OptionSummaryCard
        title={
          mobileOnly && (
            <S.TitleWrapper>
              <div>test table</div>
              {!!activeItem?.test && <S.ActiveItem>{activeItem.test}</S.ActiveItem>}
            </S.TitleWrapper>
          )
        }
        padding={0}
      >
        <OptionSummaryChart data={activeItem.yearData} />
        <OptionSummaryTable activeItem={activeItem} setActiveItem={setActiveItem} />
      </S.OptionSummaryCard>
    )

  );
};
