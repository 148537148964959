import React from 'react';
import { data as t } from '../../locale/translation';
import { Error } from '../../components/Error/Error';
import { PageTitle } from '../../components/common/PageTitle';
import error404 from '../../assets/images/error404.svg';

const Error404Page = () => {

  return (
    <>
      <PageTitle>{t.common.clientError}</PageTitle>
      <Error img={error404} msg={t.error404.notFound} />
    </>
  );
};

export default Error404Page;
