import React from 'react';
import { SecurityCodeForm } from './SecurityCodeForm/SecurityCodeForm'
import { data as t } from '../../locale/translation'
import { PageTitle } from '../../components/common/PageTitle'

const SecurityCodePage = () => {
  return (
    <>
      <PageTitle>{t.common.securityCode}</PageTitle>
      <SecurityCodeForm />
    </>
  );
};

export default SecurityCodePage;
